<template>
    <div>
        <div>
            <h2 class="th-title">Assets Tracking System</h2>
            <!-- <h2 class="th-title">Monitoring Tanggal {{ getHumanDate(monitoringDate) }}</h2> -->
            <!-- <a>
          <p class="text-muted mb-5" @click="showModalDate"> Ganti tanggal monitoring</p>
        </a> -->
            <!-- <a-modal v-model:visible="visibleDate" title="Basic Modal" @ok="handleDate">
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </a-modal> -->
        </div>
        <a-card>
            <div class="row mb-3">
                <div class="col-6 my-2 my-xl-0 col-md-3 col-xl-2 ">
                    <a-input-search placeholder="Cari Nama Aset..." v-model:value="search" />
                </div>
                <div class="col-6 my-2 my-xl-0 col-md-3 col-xl-2">
                    <a-select v-model:value="selectedFilterStatus" class="w-100">
                        <a-select-option value="Filter Tipe Asset">Filter Tipe Asset</a-select-option>
                        <a-select-option value="Active Devices">Active Devices</a-select-option>
                        <a-select-option value="Passive Devices">Passive Devices</a-select-option>
                        <a-select-option value="Facilities">Facilities</a-select-option>
                        <a-select-option value="Software">Software</a-select-option>
                        <a-select-option value="Infrastructure">Infrastructure</a-select-option>
                    </a-select>
                </div>
                <div class="col-6 my-2 my-xl-0 col-md-3 col-xl-2">
                    <a-select v-model:value="selectedFilterGaransiAsset" class="w-100">
                        <a-select-option value="Filter Status Garansi Asset">Filter Status Garansi
                            Asset</a-select-option>
                        <a-select-option value="active">Active</a-select-option>
                        <a-select-option value="expiring">Expiring</a-select-option>
                        <a-select-option value="expired">Expired</a-select-option>
                    </a-select>
                </div>
                <!-- <div class="col-6 my-2 my-xl-0 col-md-3 col-xl-2">
                    <a-select v-model:value="selectedYearStatus" class="w-100">
                        <a-select-option value="Filter Tipe Asset">Filter Per Tahun</a-select-option>
                        <a-select-option value="2020">2020</a-select-option>
                        <a-select-option value="2021">2021</a-select-option>
                        <a-select-option value="2022">2022</a-select-option>
                        <a-select-option value="2023">2023</a-select-option>
                        <a-select-option value="2024">2024</a-select-option>
                    </a-select>
                </div> -->
                <div class="col-6 my-2 my-xl-0 col-md-3 col-xl-2">
                    <a-month-picker v-model:value="selectedMonthStatus" placeholder="Filter Per Bulan" />
                </div>
                <div class="col-6 my-2 my-xl-0 col-md-3 col-xl-2">
                    <a-button @click="clearFilter">
                        <UndoOutlined />Clear Filter
                    </a-button>
                </div>
                <div class="col-6 my-2 my-xl-0 col-md-3 col-xl-2">
                    <a-button @click="exportToExcel">
                        <ExportOutlined />Export XLS
                    </a-button>

                </div>

            </div>
            <div>
                <a-table :columns="role === 'dcOwner' ? columns : columns2" :data-source="budgetData" bordered>
                </a-table>
            </div>
        </a-card>
    </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw, watch, defineComponent, h } from 'vue';
import * as XLSX from 'xlsx';
import { useStore } from 'vuex';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification, Tag } from 'ant-design-vue';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined, WarningFilled, ExportOutlined, UndoOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { useForm } from '@ant-design-vue/use';
import moment from 'moment'
import {
    generateTask, getTaskByDate,
} from '../../services/axios/modc/eos'
import {
    getVendors,
} from '../../services/axios/modc/vendor'
import {
    getBudgetPlan,
} from '../../services/axios/modc/ats'
import { getCustomers, getLocations, postUser } from '../../services/axios/modc/users'
import rupiahPrice from '../../helpers/rupiahPrice.js'
export default defineComponent({
    components: {
        ExportOutlined, UndoOutlined,
    },
    setup() {
        const columns2 = [
            {
                title: 'No',
                dataIndex: 'key',
                key: 'key',
                align: 'center',
            },
            {
                title: 'Nama Asset',
                dataIndex: 'name',
                key: 'name',
                slots: { customRender: 'name' },
                align: 'center',

            },
            {
                title: 'Tipe Asset',
                dataIndex: 'AssetSubCategory.AssetCategory.name',
                key: 'asset',
                align: 'center',
            },
            {
                title: 'Garansi Berakhir',
                dataIndex: 'MaintenanceInformation.warrantyEnd',
                key: 'category',
                align: 'center',
                customRender: ({ text }) => {
                    return h(
                        'p',
                        {
                            href: 'javascript:;',
                        },
                        moment(text).format('DD-MM-YYYY'),
                    );
                },
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                slots: { customRender: 'status' },
                customRender: ({ text, index, record }) => {
                    if (record.status === 'active') {
                        return h(
                            Tag,
                            {
                                color: '#87d068',
                            },
                            'Active', // The text inside the tag
                        );
                    }
                    else if (record.status === 'expiring') {
                        return h(
                            Tag,
                            {
                                color: '#faad14',
                            },
                            'Expiring', // The text inside the tag
                        );
                    }
                    else if (record.status === 'expired') {
                        return h(
                            Tag,
                            {
                                color: '#f50',
                            },
                            'Expired', // The text inside the tag
                        );
                    }
                },
            },
        ];
        const columns = [
            {
                title: 'No',
                dataIndex: 'key',
                key: 'key',
                align: 'center',
                customRender: ({ text, index }) => {
                    if (index === budgetData.value.length - 1) {
                        return {
                            children: h(
                                'p',
                                {
                                    href: 'javascript:;',
                                },
                                "Total :",
                            ),
                            props: {
                                colSpan: 5,
                                align: 'center',
                            },
                        };
                    }
                    return h(
                        'p',
                        {
                            href: 'javascript:;',
                        },
                        text,
                    );
                },
            },
            {
                title: 'Nama Asset',
                dataIndex: 'name',
                key: 'name',
                slots: { customRender: 'name' },
                align: 'center',
                customRender: ({ text, index }) => {
                    if (index === budgetData.value.length - 1) {
                        return {
                            children: h(
                                'p',
                                {
                                    href: 'javascript:;',
                                },
                                rupiahPrice(totalValue.value.totalAssetValue),
                            ),
                            props: {
                                colSpan: 1,
                            },
                        };
                    }
                    return h(
                        'p',
                        {
                            href: 'javascript:;',
                        },
                        text,
                    );
                },
            },
            {
                title: 'Tipe Asset',
                dataIndex: 'AssetSubCategory.AssetCategory.name',
                key: 'asset',
                align: 'center',
                customRender: ({ text, index }) => {
                    if (index === budgetData.value.length - 1) {
                        return {
                            children: h(
                                'p',
                                {
                                    href: 'javascript:;',
                                },
                                rupiahPrice(totalValue.value.totalEstimatedAssetValue),
                            ),
                            props: {
                                colSpan: 1,
                            },
                        };
                    }
                    return h(
                        'p',
                        {
                            href: 'javascript:;',
                        },
                        text,
                    );
                },
                // slots: { customRender: 'name' },
            },
            {
                title: 'Garansi Berakhir',
                dataIndex: 'warrantyEnd',
                key: 'category',
                align: 'center',
                customRender: ({ text, index }) => {
                    if (index === budgetData.value.length - 1) {
                        return {
                            children: h(
                                'p',
                                {
                                    href: 'javascript:;',
                                },
                                text,
                            ),
                            props: {
                                colSpan: 0,
                            },
                        };
                    }
                    return h(
                        'p',
                        {
                            href: 'javascript:;',
                        },
                        text ? moment(text).format('DD-MM-YYYY') : 'not inputted yet',
                    );
                },
                // slots: { customRender: 'description' },
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                slots: { customRender: 'status' },
                customRender: ({ text, index, record }) => {
                    if (index === budgetData.value.length - 1) {
                        return {
                            children: h(
                                'p',
                                {
                                    href: 'javascript:;',
                                },
                                text,
                            ),
                            props: {
                                colSpan: 0,
                            },
                        };
                    }
                    if (record.status === 'active') {
                        return h(
                            Tag,
                            {
                                color: '#87d068',
                            },
                            'Active', // The text inside the tag
                        );
                    }
                    else if (record.status === 'expiring') {
                        return h(
                            Tag,
                            {
                                color: '#faad14',
                            },
                            'Expiring', // The text inside the tag
                        );
                    }
                    else if (record.status === 'expired') {
                        return h(
                            Tag,
                            {
                                color: '#f50',
                            },
                            'Expired', // The text inside the tag
                        );
                    }
                    else if (record.status === 'unknown') {
                        return h(
                            Tag,
                            {
                                color: 'red',
                            },
                            'not inputted yet', // The text inside the tag
                        );
                    }
                },
            },
            {
                title: 'Harga Asset (IDR.)',
                dataIndex: 'value',
                key: 'procurement',
                align: 'center',
                customRender: ({ text, index }) => {
                    if (index === budgetData.value.length - 1) {
                        return {
                            children: h(
                                'p',
                                {
                                    href: 'javascript:;',
                                },
                                rupiahPrice(text),
                            ),
                            props: {
                                colSpan: 0,
                            },
                        };
                    }
                    return h(
                        'p',
                        {
                            href: 'javascript:;',
                        },
                        rupiahPrice(text),
                    );
                },
            },
            {
                title: 'Estimasi Harga Asset (IDR.)',
                dataIndex: 'estimatedValue',
                key: 'maintenance',
                align: 'center',
                customRender: ({ text, index }) => {
                    if (index === budgetData.value.length - 1) {
                        return {
                            children: h(
                                'p',
                                {
                                    href: 'javascript:;',
                                },
                                rupiahPrice(text),
                            ),
                            props: {
                                colSpan: 0,
                            },
                        };
                    }
                    return h(
                        'p',
                        {
                            href: 'javascript:;',
                        },
                        rupiahPrice(text),
                    );
                },
            },
        ];
        const customersId = ref()
        const customerOptions = ref([])
        const store = useStore()
        let budgetData = ref([])
        let search = ref('')
        const totalValue = ref()
        const role = ref()

        onMounted(() => {
            fetchBudgetPlan()
            fetchCustomers()
        })
        let realBudgetData = ref([])
        const fetchBudgetPlan = async (search = '') => {
            const storedUserData = localStorage.getItem('userData')
            const userData = JSON.parse(storedUserData)
            role.value = userData.user.role
            const { data, total } = await getBudgetPlan(customersId.value)
            data.forEach((e, idx) => {
                e.key = idx + 1
            })
            //ditambah data kosong buat dapet 1 row yang bisa di custom total
            const totalRow = { key: 'total-row' }
            if (data.length && role.value === 'dcOwner') {
                data.push(totalRow)
            }
            budgetData.value = data
            realBudgetData.value = data
            totalValue.value = total

        }
        const fetchCustomers = async (search = '') => {
            const { customer } = await getCustomers()
            customerOptions.value = customer
        }
        const exportToExcel = () => {
            // Create a new workbook
            const workbook = XLSX.utils.book_new();

            // Define the header based on the columns

            let headers = [];
            if (role.value === 'dcOwner') {
                headers = ['No', 'Nama Asset', 'Tipe Asset', 'Garansi Berakhir', 'Status', 'Harga Asset (IDR.)', 'Estimasi Harga Asset (IDR.)']
            } else {
                headers = ['No', 'Nama Asset', 'Tipe Asset', 'Garansi Berakhir', 'Status']
            }
            // Create an array of arrays from the data
            let data = []
            if (role.value === 'dcOwner') {
                data = budgetData.value.map((item, index) =>
                    [
                        item.key,
                        item.name,
                        item?.AssetSubCategory?.AssetCategory?.name,
                        moment(item?.MaintenanceInformation?.warrantyEnd).format('DD-MM-YYYY'),
                        item.status,
                        item?.value,
                        item?.estimatedValue,
                    ],
                );
            } else {
                data = budgetData.value.map((item, index) =>
                    [
                        item.key,
                        item.name,
                        item?.AssetSubCategory?.AssetCategory?.name,
                        moment(item?.MaintenanceInformation?.warrantyEnd).format('DD-MM-YYYY'),
                        item.status,
                    ],
                );

            }

            if (role.value === 'dcOwner') {
                data.pop()

                // Add the total row at the end
                data.push(['', '', '', '', '', ''], [
                    '', // Empty cell for 'Garansi Berakhir'
                    '', // Empty cell for 'Harga Pengadaan (IDR.)'
                    '', // Empty cell for 'Harga Maintenance (IDR.)'
                    '',
                    'Total:',
                    `IDR.${totalValue.value.totalAssetValue ? totalValue.value.totalAssetValue : 0}`,
                    `IDR.${totalValue.value.totalEstimatedAssetValue ? totalValue.value.totalEstimatedAssetValue : 0}`,
                ]);
            }

            // Convert the array of arrays to a worksheet
            const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

            // Set column widths
            worksheet['!cols'] = [
                { wch: 5 }, // No
                { wch: 50 }, // Nama Asset
                { wch: 25 }, // Tipe Asset
                { wch: 30 }, // Garansi Berakhir
                { wch: 20 }, // Status
                { wch: 30 }, // Harga Pengadaan (IDR.)
                { wch: 30 }, // Harga Maintenance (IDR.)
            ];

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Budget Plan');

            // Generate a binary string (can be changed to various formats e.g., binary string, base64, etc.)
            const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

            // Create a Blob from the binary string
            let blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

            // Create a link element and trigger the download
            let url = URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'MODC_Budget_Plan_ATS.xlsx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        };
        const clearFilter = () => {
            search.value = ''; selectedFilterStatus.value = 'Filter Tipe Asset'; selectedFilterGaransiAsset.value = 'Filter Status Garansi Asset'; selectedMonthStatus.value = ''; selectedYearStatus.value = 'Filter Asset By Year';
        }

        const s2ab = (s) => {
            const buffer = new ArrayBuffer(s.length);
            const view = new Uint8Array(buffer);
            for (let i = 0; i < s.length; i++) {
                view[i] = s.charCodeAt(i) & 0xff;
            }
            return buffer;
        };

        const handleChange = async () => {
            await fetchBudgetPlan()
        }
        let selectedFilterStatus = ref('Filter Tipe Asset')
        let selectedFilterGaransiAsset = ref('Filter Status Garansi Asset')
        let selectedYearStatus = ref('Filter Asset By Year')
        let selectedMonthStatus = ref()

        watch([search, selectedFilterStatus, selectedFilterGaransiAsset, selectedMonthStatus, selectedYearStatus], (to, from) => {
            // console.log(realBudgetData.value[0]?.warrantyEnd, 'inidatanya')
            console.log(moment(realBudgetData.value[0]?.warrantyEnd).format('YYYY-MM'), 'inidatanya')
            budgetData.value = realBudgetData.value;
            if (search.value) {
                budgetData.value = realBudgetData.value.filter(e =>
                    e.name && e.name.includes(search.value),
                );
            }

            if (selectedFilterStatus.value !== 'Filter Tipe Asset') {
                budgetData.value = realBudgetData.value.filter(e =>
                    e?.AssetSubCategory && e?.AssetSubCategory.AssetCategory.name === selectedFilterStatus.value,
                );
            }
            if (selectedFilterGaransiAsset.value !== 'Filter Status Garansi Asset') {
                budgetData.value = realBudgetData.value.filter(e =>
                    e?.status && e?.status === selectedFilterGaransiAsset.value,
                );
            }
            if (selectedMonthStatus.value) {
                console.log(moment(selectedMonthStatus.value).format('YYYY'), 'inifiltermonts')
                selectedYearStatus.value = moment(selectedMonthStatus.value).format('YYYY')
                budgetData.value = realBudgetData.value.filter((e) =>
                    e?.warrantyEnd && moment(e?.warrantyEnd).format('YYYY-MM') === moment(selectedMonthStatus.value).format('YYYY-MM'),
                );
                if (budgetData.value.length) {
                    budgetData.value.push({ key: 'total-row' })
                }
            }
            // if (selectedYearStatus.value) {
            //     // console.log(moment(selectedYearStatus.value).format('YYYY'), 'inifiltermonts')
            //     selectedMonthStatus.value = ''
            //     budgetData.value = realBudgetData.value.filter((e, idx) =>
            //         idx === realBudgetData.value.length - 1 || e?.warrantyEnd && moment(e?.warrantyEnd).format('YYYY') === moment(selectedYearStatus.value).format('YYYY'),
            //     );
            // }
            // console.log(selectedFilterGaransiAsset.value)
        })



        return {
            budgetData,
            totalValue,
            columns,
            columns2,
            search,
            customersId,
            exportToExcel,
            s2ab,
            customerOptions,
            fetchCustomers,
            handleChange,
            role,
            selectedFilterStatus,
            selectedFilterGaransiAsset,
            selectedYearStatus,
            selectedMonthStatus, clearFilter,
        }
    },

    methods: {
        getHumanDate: function (date) {
            return moment(date).format('DD MMM YYYY')
        },
    },
})


</script>
